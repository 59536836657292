export const menus = [
  {
    title: "dashboard",
    icon: "uil uil-analysis fs-5 me-2",
    url: { name: "Dashboard" },
  },
  {
    title: "stock",
    icon: "uil uil-exchange fs-5 me-2",
    submenus: {
      categories: { name: "Categories" },
      products: { name: "Products" },
      purchase_items: {
        name: "ItemsReceive",
        params: { action: "new" },
      },
      transfer_items: {
        name: "ItemsTransfer",
        params: { action: "new" },
      },
      requisitions: {
        name: "RequisitionCreator",
        params: { action: "new" },
      },
      purchase_order: {
        name: "PurchaseOrderCreator",
        params: { action: "new" },
      },
      adjustments: {
        name: "Adjustments",
        params: { action: "new" },
      },
    },
  },
  {
    title: "expenses",
    icon: "uil uil-wallet fs-5 me-2",
    url: { name: "Expenses" },
    submenus: {
      expenses_categories: { name: "ExpensesCategories" },
      expenses: { name: "Expenses" },
    },
  },
  {
    title: "reports",
    icon: "uil uil-file-blank fs-5 me-2",
    notifications: ["requisitions"],
    submenus: {
      purchase_report: { name: "ReceiveReport" },
      transfers_report: { name: "TransferReport" },
      requisition_report: {
        name: "RequisitionsReport",
        notification: "requisitions",
      },
      /*proforma_report: {
        name: "ProformaReport",
        notification: "proforma",
      }, 
      delivery_note_report: {
        name: "DeliveryNoteReport",
        notification: "delivery",
      },*/
      purchase_order_report: {
        name: "PurchaseOrderReport",
        notification: "purchases",
      },
      // stock_status_report: { name: "TrackerReport" },
      adjustments_report: { name: "AdjustmentsReport" },
      exipired_products: { name: "ExpiredProducts" },
      sales_report: { name: "SalesReport" },
      Payment_History_report: { name: "PaymentHistoryReport" },
    },
  },
  {
    title: "leads",
    icon: "uil uil-users-alt fs-5 me-2",
    submenus: {
      users: { name: "Users" },
      suppliers: { name: "Suppliers" },
      Clients: { name: "Clients" },
      // Payment_Modes: { name: "PaymentMode" },
      // Currencies: { name: "Currencies" },
    },
  },
  {
    title: "settings",
    icon: "uil uil-cog fs-5 me-2",
    submenus: {
      users_roles: { name: "UserRoles" },
      items_units: { name: "ItemsUnits" },
      stocks: { name: "Departments" },
    },
  },
  {
    title: "POS",
    icon: "uil uil-shopping-cart-alt fs-5 me-2",
    url: { name: "PosSale" },
  },
  /*{
    title: "",
    icon: "",
    submenus: {
      proforma: {
        name: "ProformaCreator",
        params: { action: "new" },
      },
      Point_of_sale: { name: "" },
    },
  }, */
];
