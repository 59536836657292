export const languages = [
  {
    label: "English",
    code: "en",
    flag: "gb.svg",
  },
  {
    label: "French",
    code: "fr",
    flag: "fr.svg",
  },
  {
    label: "Kinyarwanda",
    code: "rw",
    flag: "rw.svg",
  },
];
